@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --amplify-font-family: "Inter var"
}

amplify-authenticator {
  --container-height: 100%;
}

/* fonts */
@layer base {
  @font-face {
    font-family: "Fira Sans 200";
    font-weight: 200;
    src: url(assets/fonts/fira-sans-v10-latin-200.woff) format("woff");
  }
  @font-face {
    font-family: "Fira Sans 300";
    font-weight: 300;
    src: url(assets/fonts/fira-sans-v10-latin-300.woff) format("woff");
  }
  @font-face {
    font-family: "Fira Sans 500";
    font-weight: 500;
    src: url(assets/fonts/fira-sans-v10-latin-500.woff) format("woff");
  }
  @font-face {
    font-family: "Fira Sans 600";
    font-weight: 600;
    src: url(assets/fonts/fira-sans-v10-latin-600.woff) format("woff");
  }
  @font-face {
    font-family: "Fira Sans 700";
    font-weight: 700;
    src: url(assets/fonts/fira-sans-v10-latin-700.woff) format("woff");
  }
}

body {
  margin: 0;
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* mega menu css */

.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: 0;
}

.hoverable {
  position: static;
}

.hoverable > a:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

.toggleable > label:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.toggle-input {
  display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
  display: none;
}

.toggle-input:checked ~ .mega-menu {
  display: block;
}

.toggle-input:checked + label {
  color: white;
  background: #2c5282; /*@apply bg-blue-800 */
}


.toggle-input:checked ~ label:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}



:root {
  --amplify-primary-color: #4461d4;
  --amplify-primary-tint: #4d7bf3;
  --amplify-primary-shade: #4461d4;
}
